import React, {Component} from 'react'
import '../style/Stage.css'
import Scenario from './Scenario'

class Stage extends Component {

  render() {

    return(
      <div
        id="stage"
        onClick={(e) => this.props.selectDisplay(e, 'nav')}
        >
        <div className="container-scenario">
          <Scenario
            display={this.props.display}
            activeScenario={this.props.activeScenario}
            activeFrames={this.props.activeFrames}
            activeItems={this.props.activeItems}
            activePromos={this.props.activePromos}
            activeStartTime={this.props.activeStartTime}
            dataApiUse={this.props.dataApiUse}
            dataApiDaytimePriceAdult={this.props.dataApiDaytimePriceAdult}
            dataApiDaytimePriceSenior={this.props.dataApiDaytimePriceSenior}
            dataApiDaytimePriceStudent={this.props.dataApiDaytimePriceStudent}
            dataApiDaytimePriceYouth={this.props.dataApiDaytimePriceYouth}
            dataApiDaytimePriceChild={this.props.dataApiDaytimePriceChild}
            dataApiNightLifePricePublic={this.props.dataApiNightLifePricePublic}
            dataApiNightLifePriceMember={this.props.dataApiNightLifePriceMember}
            dataApiNightLifeVipPricePublic={this.props.dataApiNightLifeVipPricePublic}
            dataApiNightLifeVipPriceMember={this.props.dataApiNightLifeVipPriceMember}
          />
        </div>
      </div>
    )

  }

}

export default Stage
