import React, { Component } from 'react'
import '../style/Scenario.css'
import Frame from './Frame'

class Scenario extends Component {

  // Synchronization scheme for separate imstances of app
  // running on distinct machines with same system time:
  /*
  stay on first frame of scenario animation unt  next valid
  props.activeStartTime + scenario duration, then start animation
  and start timer to restart from head in [scenario duration] seconds.
  */

  constructor(props) {
    super(props)
    this.state = {
      activeFrame: 0
    }
    this.intervalIdAnimFrames = null
    this.timeoutId = null
    this.durationTimer = 0
    this.durationScenario = 0 // whole seconds
  }

  _getScenarioDuration() {
    // sets this.durationScenario
    this.props.activeFrames.forEach((item, i) => {
      if (item.duration > 0) {
        this.durationScenario = this.durationScenario + parseInt(item.duration)
      }
    })
  }

  _initNextCheckPoint() {
    let nextCheckPoint = null
    let now = new Date()

    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('timewarp')) {
      now = new Date(urlParams.get('timewarp'))
    }

    let next = this.props.activeStartTime
    next.setSeconds(next.getSeconds() + (this.durationScenario))
    while (!nextCheckPoint) {
      if (next > now) {
        nextCheckPoint = next
      } else {
        next.setSeconds(next.getSeconds() + (this.durationScenario))
      }
    }
    //console.log('nextCheckPoint: ' + next)
    this.timeoutId = setTimeout(() => this._initScenarioAnimation(), (nextCheckPoint.getTime() - now.getTime()))
  }

  componentWillUnmount() {
    clearInterval(this.intervalIdAnimFrames)
    clearTimeout(this.timeoutId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeScenario !== prevProps.activeScenario) {
      clearInterval(this.intervalIdAnimFrames)
      clearTimeout(this.timeoutId)
      this.intervalIdAnimFrames = null
      this.timeoutId = null
      this.durationTimer = 0
      this.durationScenario = 0
      this.setState({
        activeFrame: 0
      })
      this._getScenarioDuration()
      this._initNextCheckPoint()
    }
  }

  _initScenarioAnimation() {

    clearInterval(this.intervalIdAnimFrames)
    clearTimeout(this.timeoutId)
    this.intervalIdAnimFrames = null
    this.timeoutId = null

    this.durationTimer = this.durationScenario

    this.setState({
      activeFrame: 0
    })

    this._initNextCheckPoint()
    this.intervalIdAnimFrames = setInterval(() => this._displayFrames(), 1000)

  }

  _displayFrames() {
    this.durationTimer = this.durationTimer + 1

    if (this.durationTimer >= this.props.activeFrames[this.state.activeFrame].duration) {

      let n = 0
      if ((this.state.activeFrame + 1) < this.props.activeFrames.length) {
        n = this.state.activeFrame + 1
      }
      this.durationTimer = 0
      this.setState({
        activeFrame: n
      })
    }

  }

  render() {

    const _this = this
    let frames = []
    let divStyle = {}

    let items = []

    if ((this.props.activeScenario) && (this.props.display === 'stage')) {

      divStyle = {
        backgroundColor: this.props.activeScenario.bgcolor
      }

      this.props.activeFrames.forEach((frame, i) => {

        let fid = frame.nid
        items = _this.props.activeItems.filter((item) => {
          return item.nid === fid
        })

        frames.push(
          <Frame
            key={i}
            id={i}
            dataFrame={frame}
            activeFrame={_this.state.activeFrame}
            dataItems={items}
            dataPromos={_this.props.activePromos}
            dataApiUse={this.props.dataApiUse}
            dataApiDaytimePriceAdult={this.props.dataApiDaytimePriceAdult}
            dataApiDaytimePriceSenior={this.props.dataApiDaytimePriceSenior}
            dataApiDaytimePriceStudent={this.props.dataApiDaytimePriceStudent}
            dataApiDaytimePriceYouth={this.props.dataApiDaytimePriceYouth}
            dataApiDaytimePriceChild={this.props.dataApiDaytimePriceChild}
            dataApiNightLifePricePublic={this.props.dataApiNightLifePricePublic}
            dataApiNightLifePriceMember={this.props.dataApiNightLifePriceMember}
            dataApiNightLifeVipPricePublic={this.props.dataApiNightLifeVipPricePublic}
            dataApiNightLifeVipPriceMember={this.props.dataApiNightLifeVipPriceMember}
          />
        )

      })

    }

    return (
      <div
        id='scenario'
        style={divStyle}
        >
        <div className="container-frames">
          {frames}
        </div>
      </div>
    )

  }

}

export default Scenario
